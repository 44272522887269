import React, { useState, useEffect, useRef, useMemo } from 'react';
import DashboardNav from "../components/DashboardNav";
import { Container, Row, Col, Form } from 'react-bootstrap/';
import { useAccess } from '../components/AccessProvider';
import { useJob } from '../components/JobProvider';
import { useAuth } from '../components/AuthProvider';
import { formatAsCurrency } from '../utils';

const IBAReport = () => {
  const { ibaAccess } = useAccess();
  const [industries, setIndustries] = useState([]);
  const [reportCardSpendBrackets, setReportCardSpendBrackets] = useState([]);
  const [clientName, setClientName] = useState(null);
  const [industryName, setIndustryName] = useState(null);
  const [reportCardSpendBracketId, setReportCardSpendBracketId] = useState(null);
  const [totalJobCountAll, setTotalJobCountAll] = useState(null);
  const [benchmarkSample, setBenchmarkSample] = useState(null);
  const [preAuditAnnualSpend, setPreAuditAnnualSpend] = useState(null);
  const [savingsIdentified, setSavingsIdentified] = useState(null);
  const [preAuditSpendFlagged, setPreAuditSpendFlagged] = useState(null);
  const [preAuditScore, setPreAuditScore] = useState(null);
  const [savingsImplemented, setSavingsImplemented] = useState(null);
  const [postAuditImplemented, setPostAuditImplemented] = useState(null);
  const [postAuditScore, setPostAuditScore] = useState(null);
  const [recCount, setRecCount] = useState(null);
  const [implementedRecCount, setImplementedRecCount] = useState(null);
  const [implementationScore, setImplementationScore] = useState(null);
  const [preAuditBenchmark, setPreAuditBenchmark] = useState(null);
  const [postAuditBenchmark, setPostAuditBenchmark] = useState(null);
  const [volumeBenchmark, setVolumeBenchmark] = useState(null);
  
  const { jobId: job } = useJob();
  const { token } = useAuth();

  useEffect(() => {
    if (job) {
      fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(reportDetail => {
          setClientName(reportDetail.reportCardDetail?.clientName);
          setIndustryName(reportDetail.reportCardDetail?.category);
          setReportCardSpendBracketId(reportDetail?.reportCardDetail?.reportCardSpendBracket?.reportCardSpendBracketId);
          setBenchmarkSample(reportDetail.benchmarkSample);
          setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
          setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
          setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
          setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
          setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
          setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
          setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
          setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
          setRecCount(reportDetail?.reportCardDetail?.recCount);
          setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
          setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
          setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
          setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);

          fetch(`${process.env.REACT_APP_API_URL}/data/report_card/industry/${encodeURIComponent(reportDetail.reportCardDetail.job.industry?.category)}/state/${reportDetail.reportCardDetail.job.state}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
            .then(res => res.json())
            .then(report => {
              setTotalJobCountAll(report?.jobCountAll + report?.historicalJobCountAll);
            })

        })
        .catch(err => {
        })
    }

    fetch(`${process.env.REACT_APP_API_URL}/data/industry`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(industries => {
        setIndustries(industries);
      })

    fetch(`${process.env.REACT_APP_API_URL}/data/report_card_spend_bracket`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(reportCardSpendBrackets => {
        setReportCardSpendBrackets(reportCardSpendBrackets);
      })
  }, [job, token])

  const refreshData = (industry, reportCardSpendBracketId) => {
    setIndustryName(industry);
    setReportCardSpendBracketId(reportCardSpendBracketId);

    const params = new URLSearchParams({
      industry,
      reportCardSpendBracketId
    });

    fetch(`${process.env.REACT_APP_API_URL}/data/report_card/detail/job/${job}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .then(reportDetail => {
        //setClientName(reportDetail.reportCardDetail?.clientName);
        //setIndustryName(reportDetail.reportCardDetail?.category);
        //setReportCardSpendBracket(reportDetail.reportCardDetail.reportCardSpendBracket);
        setBenchmarkSample(reportDetail.benchmarkSample);
        setPreAuditAnnualSpend(reportDetail.reportCardDetail?.totalSpend * 12);
        setSavingsIdentified(reportDetail.reportCardDetail?.totalAnnualOp);
        setPreAuditSpendFlagged(reportDetail.reportCardDetail?.totalAnnualOp / (reportDetail.reportCardDetail?.totalSpend * 12));
        setPreAuditScore(reportDetail?.reportCardDetail?.beforeScore);
        setPreAuditBenchmark(1 - (reportDetail?.aggregateTotalAnnualOp / (reportDetail?.aggregateTotalSpend * 12)))
        setSavingsImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured);
        setPostAuditImplemented(reportDetail?.reportCardDetail?.totalAnnualCaptured / reportDetail.reportCardDetail?.totalAnnualOp);
        setPostAuditScore(reportDetail?.reportCardDetail?.afterScore);
        setRecCount(reportDetail?.reportCardDetail?.recCount);
        setImplementedRecCount(reportDetail?.reportCardDetail?.implementedRecCount);
        setImplementationScore(reportDetail?.reportCardDetail?.impPercent);
        setPostAuditBenchmark(((reportDetail?.aggregateTotalSpend * 12) - (reportDetail?.aggregateTotalAnnualOp)) / ((reportDetail?.aggregateTotalSpend * 12) - reportDetail?.aggregateTotalAnnualCaptured));
        setVolumeBenchmark(reportDetail?.aggregateImplemented / reportDetail?.aggregateTotalRecs);
      })
  }

    return (
        <>
          <DashboardNav />
          {
            ibaAccess
              ?
                <>
                  <div className="position-relative">
                    <Container className="mt-3">
                      <Row>
                        <Col xs={12}>
                          <h1 className="mb-0">Industry Benchmark Report</h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <h3 className="mb-0">{clientName}</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {/* <div className="mb-0">Industry</div> */}
                          <Form.Group>
                            <Form.Label>Industry</Form.Label>
                            <Form.Select value={industryName} onChange={(e) => refreshData(e.target.value, reportCardSpendBracketId)}>
                              <option value={'all'}>All</option>
                              {
                                industries.map(industry => (
                                  <option key={industry.industryId} value={industry.name}>{industry.name}</option>
                                ))
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                              <Form.Label>Monthly Spend Bracket</Form.Label>
                              <Form.Select value={reportCardSpendBracketId} onChange={(e) => refreshData(industryName, e.target.value)}>
                                <option value={0}>All</option>
                                {
                                  reportCardSpendBrackets.map(reportCardSpendBracket => (
                                    <option key={reportCardSpendBracket.reportCardSpendBracketId} value={reportCardSpendBracket.reportCardSpendBracketId}>{reportCardSpendBracket.label}</option>
                                  ))
                                }
                              </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-0">SpyGlass Clients</div>
                        </Col>
                        <Col xs={10}>
                          <div className="mb-0">{totalJobCountAll}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-0">Benchmark Sample</div>
                        </Col>
                        <Col xs={10}>
                          <div className="mb-0">{benchmarkSample}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>Pre Audit Efficiency</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Annual Spend</Col>
                          <Col>{formatAsCurrency(preAuditAnnualSpend)}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Savings Identified</Col>
                          <Col>{formatAsCurrency(savingsIdentified)}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>% of Spend Flagged</Col>
                          <Col>{(preAuditSpendFlagged * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>My Score</Col>
                          <Col>{(preAuditScore * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Benchmark</Col>
                          <Col>{(preAuditBenchmark * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>Post Audit Efficiency</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Savings Identified</Col>
                          <Col>{formatAsCurrency(savingsIdentified)}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Savings Implemented</Col>
                          <Col>{formatAsCurrency(savingsImplemented)}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>% Implemented</Col>
                          <Col>{(postAuditImplemented * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>My Score</Col>
                          <Col>{(postAuditScore * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Benchmark</Col>
                          <Col>{(postAuditBenchmark * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h3>Volume</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Total Recommendations</Col>
                          <Col>{recCount}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Implemented</Col>
                          <Col>{implementedRecCount}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Declined</Col>
                          <Col>{recCount - implementedRecCount}</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>My Score</Col>
                          <Col>{(implementationScore * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>Benchmark</Col>
                          <Col>{(volumeBenchmark * 100).toFixed(2)}%</Col>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </>
              : null
          }
        </>
    )
}

export default IBAReport;