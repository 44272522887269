import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import styles from './DashboardNav.module.scss';
import { useState, useEffect } from 'react'
import { useJob } from '../components/JobProvider';
import { usePortal } from './PortalProvider';
import { useAuth } from './AuthProvider';
import { useAccess } from './AccessProvider';

const DashboardNav = () => {
  const [path, setPath] = useState(null)
  const [healthCheckId, setHealthCheckId] = useState(null)
  const [spycareService, setSpycareService] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const { recAccess, invAccess, ibaAccess } = useAccess()
  const { jobId: job, spycareJobId: spycareJob } = useJob()
  const portal = usePortal();
  const { token } = useAuth();
  console.log(invAccess, portal)

  useEffect(() => {
    setLoading(true)
    setPath(document.location.pathname + document.location.search)
    const activeJob = portal === 'SpyGlass' ? job : spycareJob;
    if (activeJob) {
      fetch(`${process.env.REACT_APP_API_URL}/data/${portal === 'SpyGlass' ? 'job/' : 'spycare_job/id/'}${activeJob}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(job => {
          if (portal === 'SpyGlass') { }
          else {
            const healthCheck = job.healthChecks.sort((a, b) => b.healthCheckId - a.healthCheckId)[0]
            setHealthCheckId(healthCheck.healthCheckId);
            setSpycareService(job.spycareService.name);
          }
          setLoading(false)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [job, spycareJob, portal, token])

  return (
    <div className={styles.dashboardNav}>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto navbar-toggler collapsed" />
          <Navbar.Collapse id="basic-navbar-nav ml-auto ">
            <Nav className="spyglass ml-auto">
              <Link to="/" className={`${(path === '/' ? 'active' : '')} reset-link nav-link`} >
                Dashboard
              </Link>
              <Link to="/my-team" className={`${(path === '/my-team' ? 'active' : '')} reset-link nav-link`}>
                My Team
              </Link>
              <Link to="/file-manager" className={`${(path === '/file-manager' ? 'active' : '')} reset-link nav-link`}>
                Materials
              </Link>
              {portal === 'SpyGlass' ? (
                <>
                  <Link to="/kickoff-meeting" className={`${(path === '/kickoff-meeting' ? 'active' : '')} reset-link nav-link`}>
                    Kick-Off Meeting
                  </Link>
                  {
                    invAccess
                      ?
                      <Link to="/inventory" className={`${(path === '/inventories' ? 'active' : '')} reset-link nav-link`}>
                        Inventories
                      </Link>
                      : null
                  }
                  {
                    recAccess
                      ?
                      <>
                        <Link to="/recommendation-summary" className={`${(path === '/recommendation-summary' ? 'active' : '')} reset-link nav-link`}>
                          Recommendation Summary
                        </Link>
                        <Link to="/recommendation" className={`${(path === '/recommendation' ? 'active' : '')} reset-link nav-link`}>
                          Recommendations
                        </Link>
                      </>
                      : null
                  }
                  {ibaAccess
                    ?
                    <Link to="/report" className={`${(path === '/report' ? 'active' : '')} reset-link nav-link ${styles.needSpycare}`}>
                      Industry Benchmarking
                    </Link>
                    : null
                  }
                </>
              ) : null}
              {portal === 'SpyCare' ? (
                <>
                  <Link to="/contract-tracker" className={`${(path === '/contract-tracker' ? 'active' : '')} reset-link nav-link`}>
                    Contract Management
                  </Link>
                  {invAccess
                    ?
                    <Link to={`/inventory/health-check/${healthCheckId}`} className={`${(path === '/inventory' ? 'active' : '')} reset-link nav-link`} style={{ pointerEvents: healthCheckId ? '' : 'none' }}>
                      Inventories
                    </Link>
                    : null
                  }
                  <Link to="/health-check-summary" className={`${(path === '/health-check-summary' ? 'active' : '')} reset-link nav-link`}>
                    Recommendations
                  </Link>
                  <a href="https://clients.spyglass.net/oauth/authorize?response_type=code&client_id=visage-v1&redirect_uri=https://prodn02.mymobilitycentral.com/oauth/v1/auth.agi?ssoUrlMarker=spyglasssso&scope=read" target="_blank" rel="noreferrer" className="reset-link nav-link" style={{ pointerEvents: spycareService === 'Both' || spycareService === 'Wireless Only' ? '' : 'none' }}>
                    MobilityCare
                  </a>
                  <Link to="/health-check-history" className={`${(path === '/health-check-history' ? 'active' : '')} reset-link nav-link`}>
                    HealthCheck History
                  </Link>
                </>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default DashboardNav
